* {
  line-height: 1.5;
}

a {
  color: dodgerblue;
}

th,
td {
  padding: 6px;
}
.MuiButton-root {
  margin: 8px 16px 8px 0px !important;
}
.MuiTableRow-root .MuiButton-root {
  margin: 8px 8px 8px 8px !important;
}

.drawMenu,
.MuiBackdrop-root {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1) !important;
}
@-moz-document url-prefix() {
  .MuiBackdrop-root {
    background-color: rgba(255, 255, 255, 0.8) !important;
  }
}
.menuButton {
  margin-left: 0px !important;
}
.desktopNav {
  display: none;
}
@media (min-width: 960px) {
  .menuButton {
    display: none !important;
  }
  .desktopNav {
    margin-left: 0.5rem !important;
    margin-right: -0.5rem !important;
    display: block;
  }
}
.MuiDrawer-paperAnchorLeft {
  min-width: 240px;
}
.lookup {
  min-width: 300px;
  width: 100%;
}
.lookup td {
  padding: 3px 6px 3px 0px;
}
.MuiTableContainer .MuiTypography-h6 {
  font-size: 1.5rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0em;
  color: rgba(0, 0, 0, 0.54);
}
.MuiTableCell-root {
  border-bottom: 0 !important;
  padding: 4px 4px 4px 4px !important;
  border-bottom: 1px dotted #eeeeee !important;
}
.MuiTableFooter-root .MuiTableCell-root {
  border-bottom: 0 !important;
}
.productAttributes .MuiGrid-item {
  margin-bottom: 1rem;
}
.productList {
  list-style: none;
  margin: 0;
  padding: 0;
}
.productListItem {
  padding: 5px 0px;
}
.productType {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.productType .MuiSvgIcon-root {
  margin-right: 12px;
}
.productType span {
  font-size: 12px;
}
.loader {
  padding-bottom: 60px;
}
.hoverText {
  cursor: help;
}
.card {
  text-align: center;
}
.card .MuiCardActions-spacing {
  justify-content: center;
}
.card .MuiCardActions-spacing a {
  margin: 0px 16px 16px !important;
}

.greyIcon {
  font-size: 40px !important;
  color: rgba(0, 0, 0, 0.54);
}
.distributionList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.distributionItem {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  margin: auto;
}
.fullWidth .distributionItem {
  width: 45%;
}
@media only screen and (max-width: 700px) {
  .fullWidth .distributionItem {
    width: 100%;
  }
}
.distributionItem span {
  display: inline-block;
  padding-bottom: 0.2rem;
  padding-left: 1rem;
}
.distributionList input,
.publishbrands input {
  font-size: 13px;
}
.TableContainer-root-234 {
  height: auto !important;
}
.basePaper {
  padding: 20px;
  /* min-height: 38rem; */
}
.MuiTypography-caption {
  font-weight: bold !important;
}
.topButtons {
  float: right;
  position: relative;
  top: -2.5rem;
  margin-bottom: -5rem;
  z-index: 5;
}
.smallFont {
  font-size: 0.8rem;
  line-height: 0.8rem;
}
/* .MuiInputBase-input {
  padding-left: 12px !important;
} */
.Mui-error .MuiInputLabel-root {
  color: #f44336;
}
.Mui-error .MuiInput-underline:after {
  transform: scaleX(1);
  border-bottom-color: #f44336;
}

.MuiFab-extended.MuiFab-sizeSmall {
  padding: 0 24px !important;
}
.createNew {
  margin-right: 10px;
  vertical-align: middle;
  color: #b9012b;
}
.MuiToolbar-root .MuiIconButton-root {
  color: #b9012b;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /*your box-shadow*/
  -webkit-text-fill-color: #333;
}
.MuiAutocomplete-popper {
  z-index: 999999999 !important;
}
.tagClound .MuiAutocomplete-inputRoot {
  padding-top: 1rem !important;
}
.authorImage {
  width: 100px;
  height: 100px;
  margin-right: 1rem;
}
table td {
  font-size: 0.9rem !important;
  line-height: 0.9rem !important;
  /* width: auto !important; */
  /*   overflow: hidden;
  display: inline-block; */
  /* white-space: nowrap; */
}

.report-numbers {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.report-numbers__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(40% - 120px);
  padding: 10px 120px 10px 0px;
}
.report-numbers__row--wide {
  width: calc(80% - 120px);
  border-bottom: 1px solid #AAA;
  padding-right: 0px;
}
.report-numbers__row--longer {
  width: calc(100% - 120px);
}
.report-numbers__row-title {
  font-weight: normal;
  margin: 0;
}
.report-numbers__row-value {
  font-size: 1.2rem !important;
  font-weight: bold;
  margin: 0;
}
.report-numbers__row-select-container {
  flex-grow: 1;
  padding-left: 20px;
}
.report-numbers__row-select {
  width: 120px;
}
.report-incomplete-section,
.report-popular-section {
  margin-top: 30px;
}
.reportTable .MTableHeader-header-29 {
  z-index: 0;
}
.DateInput_input {
  box-sizing: border-box;
}
.DateInput_input__focused {
  border-color: #2979ff !important;
}
.CalendarDay__selected {
  background: #2979ff !important;
  border-color: #2979ff !important;
}
.CalendarDay__selected:hover{
  background-color: rgb(41,121,255,0.9) !important;
}
.CalendarDay__selected_span {
  background: rgb(41,121,255,0.5) !important;
  border-color: rgb(41,121,255,0.6) !important;
}
.CalendarDay__selected_span:hover {
  background: rgb(41,121,255,0.6) !important;
}
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: rgb(41,121,255,0.5) !important;
  border-color: rgb(41,121,255,0.6) !important;
  color: white !important;
}
.provision-report-top {
  display: flex;
  justify-content: space-between;
}
.provision-report-top .report-numbers {
  width: 50%;
}
.date-range-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.date-range-container .MuiButton-root {
  margin: 10px 0px !important;
}

.jtk-endpoint {
  z-index: 10;
}

.productTabs .MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

/* .MuiTableCell-alignLeft {
  width: 10px;
  background: red;
  max-width: 32em;
  text-overflow: inherit;
  overflow: scroll;
} */
/*


author - ""title_before" is not allowed to be empty"
\\

audiobook
2020-04-20T13:14:42+00:00 

\

minio
storage
product
manager api
manager app


------


Brand doesnt have it's managment section?

publisher
brand

authors
series
editions

genres
categories

assets
Soubory určené k distribuci

?
Provision requests?


-------------


- /product API - fields?
- /product/:id - other tables?
- missing section Edice?

- publishers
- styles
- anonymouse components default exports


*/
